<template>
  <div class="portal-list">
    <PortalCard v-for="item in items" :key="item.id" :data="item" />
  </div>
</template>

<script>
import PortalCard from "./PortalCard.vue";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PortalCard,
  },
};
</script>

<style lang="stylus">
.portal-list {
  width 100%
  max-width var(--max-width)
  margin 70px auto
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-gap 30px

  +below(1430px) {
    padding 0 30px
    grid-template-columns repeat(3, 1fr)
  }
  +below(950px) {
    grid-template-columns repeat(2, 1fr)
  }

  +below(562px) {
   grid-template-columns repeat(1, 1fr)
  }
}
</style>
