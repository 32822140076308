<template>
  <main>
    <HeaderComponent />
        <WelcomeSectionComponent />
    <PortalList :items="governments" />
  </main>
</template>

<script>
import HeaderComponent from "./components/Header.vue";
import PortalList from "./components/PortalList.vue";
import GOVERMENTS from "gql/queries/governments.graphql";
import WelcomeSectionComponent from "./components/WelcomeSectionComponent.vue";
export default {
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: GOVERMENTS,
      })
      .then(({ data }) => {
        store.dispatch("portal/save", data);
      })
      .catch(() => {});
  },
  name: "PortalPage",
  data() {
    return {
      items: [
        {
          id: 1,
          title: "test",
          img: null,
        },
        {
          id: 2,
          title: "test2",
          img: null,
        },
        {
          id: 3,
          title: "test3",
          img: null,
        },
        {
          id: 4,
          title: "test4",
          img: null,
        },
      ],
    };
  },
  computed: {
    governments() {
      return this.$store.state.portal.governments;
    },
  },
  components: {
    HeaderComponent,
    WelcomeSectionComponent,
    PortalList,
  },
};
</script>

<style lang="stylus"></style>
