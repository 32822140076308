<template>
  <div class="arrows" :class="{ 'arrows--white': white }">
    <button type="button" class="arrows__button arrows__button--left" @click="$emit('prev')">
      <IconComponent category="vuesax-bold" name="arrow-circle-left" />
    </button>
    <div class="arrows__info">
      <span>{{ current | two_digit_number }}</span>
      <span class="arrows__line"></span>
      <span>{{ length | two_digit_number }}</span>
    </div>
    <button type="button" class="arrows__button arrows__button--right" @click="$emit('next')">
      <span class="arrows__progress" aria-hidden="true" ref="progressBar"></span>
      <IconComponent category="vuesax-bold" name="arrow-circle-right" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SliderArrowsComponent",
  props: {
    current: Number,
    length: Number,
    duration: Number,
    white: Boolean,
  },
  data() {
    return {
      bar: null,
    };
  },
  methods: {
    stop() {
      if (this.bar) {
        this.bar.stop();
      }
    },
    start() {
      if (this.bar) {
        if (this.length > 1) {
          this.bar.animate(1, () => {
            this.$emit("next");
            this.bar.set(0);
            this.start();
          });
        }
      }
    },
  },
  mounted() {
    require(["progressbar.js"], (ProgressBar) => {
      this.bar = new ProgressBar.Circle(this.$refs.progressBar, {
        strokeWidth: 6,
        easing: "linear",
        duration: this.duration * 1000,
        color: "#246ABF",
        trailColor: this.white ? "#DAE5EF" : "#6B708E",
        trailWidth: 6,
        svgStyle: null,
      });
      this.start();
    });
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.arrows {
  display flex
  align-items center
  gap 30px

  &--white {
    & ^[0]__button {
      background var(--white)
      border-color var(--gray-dark)

      .icon svg path {
        fill var(--gray-dark)
      }

      &--right .icon svg path {
        fill var(--main)
      }
      &--left .icon svg path {
        fill var(--main)
      }

    }

    & ^[0]__info {
      color var(--dark-light)
    }

    & ^[0]__line {
      background var(--dark)
    }
  }

  &__button {
    border: 1px solid var(--dark-light);
    border-radius: 100px;
    background transparent
    width 50px
    height: 50px;
    padding 0
    display inline-flex
    align-items center
    justify-content center
    position relative

    .icon {
      width 20px
      height: 20px;

      svg path {
        fill var(--white)
      }
    }
  }

  &__button--right {
    //top 300px
    //right -25px
    background var(--white)
    border none
    +below(860px) {
      display none
    }

    .icon svg path {
      fill var(--main)
    }
  }
  &__button--left {
    //top 300px
    //left -25px
    background var(--white)
    border none
    +below(860px) {
      display none
    }

    .icon svg path {
      fill var(--main)
    }
  }

  &__progress {
    width 100%
    height: 100%;
    absolute left top
  }

  &__info {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 17px;
    color: var(--white);
    display flex
    align-items center
    gap 5px
  }

  &__line {
    width 40px
    height: 1px;
    background var(--white)
  }
}
</style>
