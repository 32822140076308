<template>
  <router-link :to="'/portal/' + data.id" class="portal-card">
    <div class="portal-card__img">
      <ImgComponent :img="data.img" />
    </div>
    <div class="portal-card__title">
      {{ data.title }}
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.portal-card {
  background-color var(--white)
  border-radius 20px
  min-height 350px
  border 1px solid var(--white)
  transition var(--transition)

  &:hover {
    border 1px solid var(--main)
  }

  &__img {
    padding 15px

    img {
      border-radius 20px
      width 100%
      min-height 207px
      height 100%
      object-fit cover
    }
  }
  &__title {
    margin-bottom 10px
    padding 0 15px
    font-size 1.125
    font-weight 500
    word-wrap normal
  }
}
</style>
