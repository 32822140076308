<template>
  <header class="header">
    <div class="header__container">
      <router-link to="/portal" class="header__logo">
        <IconComponent category="default" name="logo-blue" />
        <pre>
Портал мер поддержки членов семей мобилизованных граждан
        </pre>
      </router-link>
    </div>
  </header>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HeaderComponent",
  computed: {
    news_categories() {
      return this.$store.state.news_categories.map((c) => ({
        title: c.title,
        to: {
          name: "press-list",
          query: {
            category: [c.id],
          },
        },
      }));
    },
    department() {
      return this.$store.state.department;
    },
    about_activities() {
      return this.$store.state.activities.filter((a) => a.type.code === 2);
    },
    activities() {
      return this.$store.state.activities.filter((a) => a.type.code === 1);
    },
  },
  components: { IconComponent },
};
</script>
